$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mobile header button
    // *
    // *
    $(".js-headerbutton").click(function(){
        $(".js-headernav").toggleClass("active");
        $("body").toggleClass("freeze");
    });

    $(".js-headernav li a").click(function() {
        $(".js-headernav").removeClass("active");
        $("body").removeClass("freeze");
    })




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * trigger fancy button
    // *
    // *
    $(".js-fancybutton").click(function(){
        $(this).parents(".js-fancyparent").find(".js-fancytarget").trigger("click");
    });
    


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderImage
    // *
    // *
    var $sliderImage = $(".js-sliderimage");

    if ($sliderImage.length) {
        $sliderImage.each(function () {
            $(this).slick({
                fade: true,
                dots: true,
                infinite: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 800,
                pauseOnHover: true,
            });
        })
    };    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderCard
    // *
    // *
    var $sliderCard = $(".js-slidercard");

    if ($sliderCard.length) {
        $sliderCard.each(function () {
            var $sliderWrapper = $(this).parents(".js-slidercardwrapper");

            $(this).slick({
                fade: true,
                dots: false,
                infinite: true,
                arrows: true,
                adaptiveHeight: false,
                prevArrow: $sliderWrapper.find(".js-slidercardprev"),
                nextArrow: $sliderWrapper.find(".js-slidercardnext"),
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 800,
                pauseOnHover: true,
            });

            // set total
            var total = $(this).find(".slick-slide:not(.slick-cloned)").length;
            $sliderWrapper.find(".js-slidercardtotal").html(total);

            // set current number
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $sliderWrapper.find(".js-slidercardcurrent").html(nextSlide + 1);
            });
        })
    };    
    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  marquee
    // *
    // *
    var $marquee = $(".js-marquee");

    if ($marquee.length) {
        $marquee.each(function () {
            $(this).slick({
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000,
                // speed: 8000,
                cssEase: 'linear',
                arrows: false,
                variableWidth: true,
                pauseOnHover: true

            });
        })
    };



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordionitem").click(function(){
        $(this).toggleClass("active").find(".js-accordioncontent").slideToggle().parent(".js-accordionitem").siblings(".js-accordionitem").removeClass("active").find(".js-accordioncontent").slideUp();
    });

    $(".js-accordionitem:first-child").trigger("click");

});




$(window).on('load scroll', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Nav Active when Element is in Viewpoint
    // *
    // *
    var $sections = $("[data-nav-section]"),
    $nav = $(".js-headernav"),
    navHeight = $(".js-header").outerHeight() + 40;
    
    var cur_pos = $(this).scrollTop();

    $sections.each(function() {
        var top = $(this).offset().top - navHeight, 
            bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
            $nav.find('li').removeClass('active');
            // $sections.removeClass('active');
            // $(this).addClass('active');
            $nav.find('a[href="#' + $(this).data('nav-section') + '"]').parent('li').addClass('active');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky Header
    // *
    // *
    if($(window).scrollTop() >= 80) {
        $(".js-chatbot").removeClass("show");
    }else {
        $(".js-chatbot").addClass("show");
    }
});